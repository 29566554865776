.about {
  background-color: var(---about-bg-color);

  padding: 5rem 0;
}
.about_container {
  display: flex;
  max-width: 120rem;
  width: 80%;
  margin: auto;
  align-items: center;
}
.about_right {
  padding: 5rem;

  flex-basis: 50%;
}
.about_right img {
  width: 100%;
  filter: var(--about-img-shadow);
}
.about_left {
  padding: 5rem;

  flex-basis: 50%;
  display: flex;
  gap: 4rem;
  flex-direction: column;
}
.about_left h2 {
  font-size: 4rem;
}
.about_left p {
  font-size: 2rem;
  line-height: 1.5;
}

@media screen and (max-width: 850px) {
  .about_container {
    display: flex;

    width: 95%;

    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .about_right {
    display: flex;
    padding-bottom: 0;
  }
  .about_right img {
    width: 70%;
    margin: auto;
  }
}
