.client {
  background-color: var(--client-bg-color);

  padding: 4rem 0;
  padding-bottom: 0.5rem;
}

.client h1 {
  text-align: center;
  font-size: 4rem;
}

.client h3 {
  text-align: center;
  font-size: 1.6rem;
  color: var(--color-text-color);
  margin-top: 2rem;
  font-weight: 400;
  margin: 0 1rem;
}

.client .contain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 120rem;
  width: 80%;
  margin: 5rem auto;
}

.client .contain .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.client .contain .box h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.client .contain .box p,
.client .contain .box h3 {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.7;
}
.client .contain .box h3 {
  font-weight: 500;
}

.client .contain .box h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.client .contain .box .whitebox {
  background-color: var(--client-whitebox);
  padding: 3rem 1.7rem;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
}
.client .contain .box .whitebox::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  background-color: var(--client-whitebox);
  border-top: 20px solid var(--client-whitebox);

  border-left: 20px solid var(--client-bg-color);
  border-right: 20px solid var(--client-bg-color);
}

@media screen and (max-width: 850px) {
  .client .contain {
    grid-template-columns: repeat(1, 1fr);
    max-width: 40rem;
    gap: 5rem;
  }
}
