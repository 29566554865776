h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 2rem;
  /* margin: 0 1rem; */
}
p {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
}
.left {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: flex-start;
}

.header .box {
  color: var(--color-text-color);
  max-width: 120rem;
  display: flex;
  gap: 4rem;
  width: 80%;
  margin: auto;
  align-items: center;
  padding: 4rem 0;
}
.right {
  flex-basis: 50%;
  padding: 2rem 10rem;
  max-width: 44rem;
}
.web_dev {
  width: 100%;
  max-width: 35rem;
  display: block;
  filter: var(--header-img-drop-shadow);
  animation: dev 1s ease-in-out infinite alternate;
}

a {
  color: white;
  text-transform: none;
  font-weight: 600;
  font-size: 1.8rem;
  text-decoration: none;
}
@keyframes dev {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1100px) {
  .left {
    flex-basis: 60%;
  }
  .right {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 1000px) {
  .left {
    flex-basis: 100%;
  }
  .right {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 940px) {
  .left {
    flex-basis: 100%;
  }
  .right {
    flex-basis: 100%;
    padding: 0;
  }
  .header .box {
    justify-content: center;
    flex-direction: column-reverse;
  }
  .web_dev {
    max-width: 30rem;
  }
}

@media screen and (max-width: 600px) {
  .web_dev {
    max-width: 20rem;
    min-width: 20rem;
  }
}

button.btn {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  border-radius: 1000px;
  position: relative;
  z-index: 1;
  background-color: #000000;
}

.btn-container {
  position: relative;
  padding: 3px;
  background: #000000;
  border-radius: 43px;
  overflow: hidden;
}

.btn-container::before {
  content: "";
  position: absolute;
  inset: -100%;
  width: 300%;
  height: 300%;
  background: conic-gradient(
    from 0deg,
    transparent 0%,
    #37cefc 30%,
    transparent 5%
  );
  animation: rotate 3s ease-out infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
