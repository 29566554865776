.works {
  background-color: var(--works-bg-color);

  padding: 4rem 0;
}
.works h2 {
  font-size: 4rem;
  color: var(--color-text-color);
  text-align: center;
  margin-bottom: 5rem;
}
.works h3 {
  font-size: 2rem;
  color: var(--color-text-color);
}
.works h4 {
  font-size: 1.6rem;
  color: var(--color-text-color);
  font-weight: 500;
}

.works .contain {
  margin: auto;
  max-width: 120rem;
  width: 80%;
  display: grid;
  column-gap: 10vw;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    /* margin: unset; */
  }
  @media screen and (max-width: 800px) {
    margin: auto;
  }
}
.works .box {
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 1200px) {
    margin: auto;
  }
  @media screen and (max-width: 800px) {
    width: unset;
  }
}

.works img {
  border-radius: 1rem;
  align-self: stretch;
  width: 100%;
  height: 100%;
  display: block;

  width: 35vw; /* Fixed width */
  height: 35vw; /* Fixed height */
  max-width: 50rem;
  max-height: 50rem;
  object-fit: cover; /* Maintains aspect ratio and covers the container */
  @media screen and (max-width: 1200px) {
    width: 60vw; /* Fixed width */
    height: 60vw; /* Fixed height */
  }

  @media screen and (max-width: 800px) {
    width: 70vw; /* Fixed width */
    height: 70vw; /* Fixed height */
  }
}

@media screen and (min-width: 1700px) {
  .works .contain {
    column-gap: 16rem;
    margin: auto;
    max-width: 120rem;
  }
  .works img {
    max-width: 52rem;
    max-height: 52rem;
  }
}

@media screen and (max-width: 600px) {
  .works .contain {
    margin: auto;
    grid-template-columns: repeat(1, 1fr);
  }
}

.imageOverlay {
  position: relative;
  border-radius: 1rem;
}
.imageOverlay a {
  border-radius: 1rem;
  font-size: 25px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(31, 31, 31);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: white;
  text-shadow: black 1px 1px 1px;
}

.imageOverlay a:hover {
  cursor: pointer;
  animation: fadein 0.2s ease-in forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

.works {
  background-color: var(--works-bg-color);
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
}

.works h2 {
  font-size: 4rem;
  color: var(--color-text-color);
  text-align: center;
  margin-bottom: 5rem;
  position: relative;
}

.works h2::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #4776e6, #8e54e9);
  border-radius: 2px;
}

.works h3 {
  font-size: 2.2rem;
  color: var(--color-text-color);
  margin-bottom: 0.5rem;
}

.works h4 {
  font-size: 1.6rem;
  color: var(--color-text-color);
  font-weight: 500;
  opacity: 0.8;
}

.works .box {
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding-bottom: 6rem;
  transition: transform 0.3s ease;
}

.works .box:hover {
  transform: translateY(-10px);
}

.imageOverlay {
  position: relative;
  border-radius: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.imageOverlay:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.imageOverlay a {
  border-radius: 1.5rem;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
  text-decoration: none;
}

.imageOverlay a:hover {
  cursor: pointer;
  opacity: 1;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1700px) {
  .works::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      45deg,
      rgba(71, 118, 230, 0.05),
      rgba(142, 84, 233, 0.05)
    );
    z-index: 0;
  }

  .works .contain {
    position: relative;
    z-index: 1;
  }
}
