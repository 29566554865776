.contact {
  background-color: var(--contact-bg-color);

  padding: 4rem 0 4rem 0;
}

.contact h2 {
  font-size: 4rem;
  text-align: center;
}
.contact h5 {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 400;
  margin: 2rem 0;
}
.contact_box {
  width: 50%;
  max-width: 120rem;
  margin: auto;
}
form {
  margin-top: 5rem;
}
.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem 3rem;
}

label {
  font-size: 1.6rem;
  font-weight: 700;
}
::placeholder,
.Message::placeholder {
  font-size: 1.6rem;
}
.contact_one {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
[type],
textarea {
  border: none;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 10px;
  background-color: var(--contact-bg-color);
  padding: 2rem 2rem;
  font-size: 1.6rem;
  font-family: sans-serif;
  color: var(--contact-from-text-color);
}

textarea::placeholder {
  color: rgb(177, 177, 177);
}
.textarea {
  font-family: sans-serif;
}
.contact_one.text_area {
  grid-column: 1/-1;
}
.btn {
  padding: 1.5rem 5rem;
  grid-column: 1/-1;
  justify-self: flex-end;
  cursor: pointer;
  background: var(--color-btn);
  color: var(--sumbit-btn);
}

[type]:focus {
  outline: 2px solid var(--color-btn);
  background-color: var(--works-bg-colo);
}

.contact .success {
  grid-column: span 2;
  font-size: 1.6rem;
  color: var(--color--success);
}

@media screen and (max-width: 870px) {
  .contact_box {
    width: 60%;
  }
}

@media screen and (max-width: 770px) {
  .contact_box {
    width: 80%;
  }
  .contact-form {
    width: 100%;
    grid-template-columns: 1fr;
  }
  .text_area {
    grid-column: 1/-1;
  }
  .contact_one {
    grid-column: 1/-1;
  }
}
