.service {
  background-color: var(--service-bg-color);
  padding: 2rem 0;
}

.service .contain {
  max-width: 120rem;
  width: 80%;
  margin: 7rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 5rem 4rem;
  justify-items: center;
}

.service [class*="box"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.service h2 {
  padding-top: 3rem;
  text-align: center;
  font-size: 4rem;
  color: var(--color-text-color);
}

.service h3 {
  color: var(--color-text-color);
  font-size: 2.2rem;
}
.service p {
  color: var(--color-text-color);
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (max-width: 350px) {
  .service .contain {
    grid-template-columns: 1fr;
  }
}
