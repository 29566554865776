.skill_icons {
  fill: var(--svg_icon);
  filter: grayscale(1);
  width: 9rem;
  transition: all 0.5s;
}
.border {
  width: 100%;
  margin: 1.5rem auto;
  height: 1px;
  background-image: linear-gradient(
    45deg,
    rgb(255, 255, 255),
    rgb(218, 218, 218),
    rgb(197, 197, 197),
    rgb(233, 233, 233),
    rgb(255, 255, 255)
  );
}
.icon_box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
  width: 80%;
  max-width: 120rem;

  /* /\width: 100vw; */
  /* animation: slide 10s ease-in-out infinite alternate-reverse; */
  /* transform: translateX(0%); */
  /* justify-content: center; */
}

@keyframes slide {
  to {
    transform: translateX(20%);
  }
}
.skill_icons:hover {
  transition: all 0.5s;

  fill: var(--skill-icon-hover);
  transform: translateY(-10px);
}

/* .skill_icons:last-child:hover {
  transition: all 0.3s;

  fill: rgb(7, 4, 0);
  filter: brightness(0.8);
  transform: translateY(-10px);
} */
@media screen and (max-width: 450px) {
  .icon_box {
    align-items: center;
    justify-content: unset;
    gap: 1rem;
  }
}
