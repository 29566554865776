*,
*:after,
*:before,
html {
  padding: 0;
  margin: 0;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
*::selection {
  background-color: #4a45bd;
  color: white;
}
body {
  font-family: sans-serif, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 62.5%;
  overflow-x: hidden;
  color: var(--color-text-color);
  scroll-behavior: smooth;
  background-color: var(--body-bg-color);
}
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: rgb(231, 231, 231);
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #4a45bd;
  border-radius: 16px;
  /* border: 4px solid #fff; */
}

.anchor {
  display: block;
  height: 92px;
  margin-top: -92px;
  visibility: hidden;
}

:root,
:root.light {
  --color-header-bg-color: #f0eefc;
  --color-nav-font: #4a45bd;
  --color-nav-font-links: #3d3d3d;
  --color-btn: #4a45bd;
  --color-text-color: #545454;
  --svg_icon: #bdbdbd;
  --service-bg-color: #fbfbff;
  --works-bg-color: #f9f9f9;
  --client-bg-color: #f4f5f7;
  --client-whitebox: white;
  ---about-bg-color: #fbfbff;
  --contact-bg-color: #f9f9f9;
  ---footer-font-color: white;
  --nav-bar-bg-color: white;
  scroll-behavior: smooth;
  --header-img-drop-shadow: drop-shadow(5px 5px 10px rgb(133, 133, 133));
  --body-bg-color: rgb(255, 255, 255);
  --skill-icon-hover: rgb(7, 4, 0);
  --about-img-shadow: drop-shadow(2rem 1rem 1rem rgb(180, 180, 180));
  --color--success: #4a45bd;
  --sumbit-btn: rgb(255, 255, 255);
  --contact-from-text-color: rgb(0, 0, 0);
}

:root.dark {
  --color-header-bg-color: #1d1d1d;
  --color-nav-font: #4a45bd;
  --color-nav-font-links: #3d3d3d;
  --color-btn: #4a45bd;
  --color-text-color: #ffffff;
  --svg_icon: #ffffff;
  --service-bg-color: #1d1d1d;
  --works-bg-color: #1d1d1d;
  --client-bg-color: #1d1d1d;
  --client-whitebox: rgb(151, 151, 151);
  ---about-bg-color: #1d1d1d;
  --contact-bg-color: #1d1d1d;
  ---footer-font-color: white;
  --nav-bar-bg-color: white;
  --header-img-drop-shadow: none;
  --body-bg-color: #1d1d1d;
  --skill-icon-hover: #b8acff;
  --about-img-shadow: none;
  --color--success: #ffffff;
  --sumbit-btn: rgb(255, 255, 255);
  --contact-from-text-color: rgb(255, 255, 255);
}
.nav_bar {
  padding: 1rem 0rem;
  position: fixed;
  width: 100%;
  background-color: var(--nav-bar-bg-color);
  z-index: 10;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.nav_box {
  margin: 0 auto;
  max-width: 120rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}

h3 {
  color: var(--color-nav-font);
  font-size: 2.2rem;
}
ul {
  list-style: none;
  font-size: 1.6rem;
  display: flex;
  gap: 2rem;
  color: var(--color-nav-font-links);
  align-items: center;
}
li {
  padding: 1rem 0rem;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}
.nav_box a {
  text-decoration: none;
  color: #545454;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
  padding: 1rem 0.5rem;
}
.nav_box a:hover {
  transition: all 0.2s;
  cursor: pointer;
  color: var(--color-nav-font);
}
.links li {
  position: relative;
}
.links li::after {
  transition: all 0.2s;
  content: "";
  position: absolute;
  width: 0;
  height: 0.3rem;
  background-color: var(--color-nav-font);

  bottom: 0;
  left: 0;
  margin: auto;
  margin-left: 50%;
}

li:hover::after {
  margin-left: 0;
  width: 100%;
}

.svg {
  width: 2rem;
  height: 2rem;
}

.icons li {
  padding-bottom: 0.5rem;
}

.icons li:hover {
  transition: all 0.2s;
  transform: translateY(-2px);
  cursor: pointer;
}

.nav_box svg {
  width: 3rem;
  height: 3rem;
  visibility: hidden;

  opacity: 0;
}
.header {
  margin-top: 5.9rem;
  width: 100%;
  padding: 5rem 0;
  background-color: var(--color-header-bg-color);
  background-image: url("img/bg.svg");
}
#menu_svg {
  width: 2.3rem;
  height: 2.3rem;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@media screen and (max-width: 750px) {
  .nav_bar {
    position: relative;
    padding: 2rem 0;
    transition: all 0.3s;
  }
  .nav_bar h3 {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .nav_box {
    display: grid;
    grid-template-columns: 1fr 50px 20px;
    /* grid-template-rows: max-content max-content max-content; */
    gap: 2rem;
  }

  li {
    padding: 0.5rem 0;
  }
  #mode-1,
  #mode-2 {
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .icons {
    grid-column: 1/-1;
    grid-row: 3/4;
  }
  .nav_box svg {
    cursor: pointer;
    visibility: visible;

    opacity: 1;
    grid-column: 3/4;
    grid-row: 1/2;
  }
  .links {
    flex-direction: column;
    align-items: flex-start;
    grid-row: 2/3;
    grid-column: span 2;
  }
  .header {
    margin-top: 0;
  }
}

.btn {
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 1rem 2rem;
}

.back-to-top {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  border-radius: 5px;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
  background: var(--color-btn);
}

.back-to-top:active {
  opacity: 60%;
}

.hidden {
  opacity: 0%;
}

.back-to-top-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: #ffffff;
}

.progress-bar {
  height: 0.5rem;
  background: var(--color-btn);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
}

#mode-1,
#mode-2 {
  z-index: 20;
  visibility: visible;
  opacity: 1;
  width: 2.5rem;
  height: 2.5rem;
}

html,
body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
