.footer {
  background-color: var(--color-nav-font);

  color: var(---footer-font-color);
  font-size: 1.6rem;
  padding: 2rem 0;
}

.footer_box {
  display: flex;
  align-items: baseline;
  margin: auto;
  width: 90%;
  max-width: 120rem;
  justify-content: center;
  gap: 1rem;
}

.footer p {
  font-weight: 800;
  cursor: pointer;
  padding: 1rem;
}
.footer ul {
  display: flex;
  align-items: center;
}

.footer li {
  color: var(---footer-font-color);
  font-weight: 500;
  font-family: sans-serif;
  padding: 0;
}
.footer a {
  text-decoration: none;
  color: var(---footer-font-color);
  font-size: 1.6rem;
}

.footer li:hover {
  color: var(---footer-font-color);
  text-decoration: underline;
}

@media screen and (max-width: 870px) {
  .footer .footer_box {
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%;
    max-width: 120rem;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
  }
  .footer ul {
    flex-direction: column;
  }
}
